import _remove from 'lodash/remove';
import * as PostActions from '../actions/post';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  postList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  postDetails: {
    isFetching: false,
    isFetched: false,
    postData: [],
  },
};

const setPostListFetching = fetching => state => ({
  ...state,
  postList: {
    ...state.postList,
    isFetching: fetching,
  },
});

const setPostList = (state, { payload: { listData, total } }) => ({
  ...state,
  postList: {
    ...state.postList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setPostFetching = fetching => state => ({
  ...state,
  postDetails: {
    ...state.postDetails,
    isFetching: fetching,
  },
});

const setPost = (state, { payload: { postData } }) => ({
  ...state,
  postDetails: {
    ...state.postDetails,
    postData,
    isFetching: false,
    isFetched: true,
  },
});

const setPostDeleted = (state, { payload: { postId } }) => {
  const newListData = state.postList.listData;
  _remove(newListData, { id: postId });

  const newState = {
    ...state,
    postList: {
      ...state.postList,
      listData: newListData,
    },
  };
  return newState;
};

const setTotalPosts = (state, { payload: { total } }) => ({
  ...state,
  postList: {
    ...state.postList,
    total
  }
});

const actionMap = {
  [PostActions.LIST_POST_REQUEST]: setPostListFetching(true),
  [PostActions.LIST_POST_SUCCESS]: setPostList,
  [PostActions.LIST_POST_FAILURE]: setPostListFetching(false),
  [PostActions.DELETE_POST_SUCCESS]: setPostDeleted,
  [PostActions.FETCH_POST_REQUEST]: setPostFetching(true),
  [PostActions.FETCH_POST_SUCCESS]: setPost,
  [PostActions.FETCH_POST_FAILURE]: setPostFetching(false),
  [PostActions.COUNT_POST_SUCCESS]: setTotalPosts,
};

export default reducerWithActionMap(actionMap, initialState);


