import getAxiosInstance from '../config/http';

export const LIST_ISSUE_REQUEST = 'ISSUE@LIST_ISSUE_REQUEST';
export const LIST_ISSUE_SUCCESS = 'ISSUE@LIST_ISSUE_SUCCESS';
export const LIST_ISSUE_FAILURE = 'ISSUE@LIST_ISSUE_FAILURE';

export const listIssues = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_ISSUE_REQUEST,
  });
  try{
    const issueData = await http.get('/reports',{params});
    dispatch({
      type: LIST_ISSUE_SUCCESS,
      payload: {
        listData: issueData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_ISSUE_FAILURE,
    });
  }
};

export const COUNT_ISSUE_REQUEST = 'ISSUE@COUNT_ISSUE_REQUEST';
export const COUNT_ISSUE_SUCCESS = 'ISSUE@COUNT_ISSUE_SUCCESS';
export const COUNT_ISSUE_FAILURE = 'ISSUE@COUNT_ISSUE_FAILURE';

export const countIssues = (params, countType) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_ISSUE_REQUEST,
  });
  try{
    const issueData = await http.get('/reports/count',{params});
    dispatch({
      type: COUNT_ISSUE_SUCCESS,
      payload: {
        total: issueData.data,
        countType
      },
    });
    return issueData.data;
  }catch(e){
    dispatch({
      type: COUNT_ISSUE_FAILURE,
    });
  }
};

export const FETCH_ISSUE_REQUEST = 'ISSUE@FETCH_ISSUE_REQUEST';
export const FETCH_ISSUE_SUCCESS = 'ISSUE@FETCH_ISSUE_SUCCESS';
export const FETCH_ISSUE_FAILURE = 'ISSUE@FETCH_ISSUE_FAILURE';

export const fetchIssue = issueId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_ISSUE_REQUEST,
  });
  try{
    const issueData = await http.get(`/reports/${issueId}`);
    dispatch({
      type: FETCH_ISSUE_SUCCESS,
      payload: {
        issueData: issueData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_ISSUE_FAILURE,
    });
  };
};

export const UPDATE_ISSUE_REQUEST = 'ISSUE@UPDATE_ISSUE_REQUEST';
export const UPDATE_ISSUE_SUCCESS = 'ISSUE@UPDATE_ISSUE_SUCCESS';
export const UPDATE_ISSUE_FAILURE = 'ISSUE@UPDATE_ISSUE_FAILURE';

export const updateIssue = (id, issueData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_ISSUE_REQUEST,
  });
  try{
    const data = await http.put(`/reports/${id}`, issueData);
    dispatch({
      type: UPDATE_ISSUE_SUCCESS,
      payload: {
        issueData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_ISSUE_FAILURE,
    });
  };
};

export const DELETE_ISSUE_REQUEST = 'ISSUE@DELETE_ISSUE_REQUEST';
export const DELETE_ISSUE_SUCCESS = 'ISSUE@DELETE_ISSUE_SUCCESS';
export const DELETE_ISSUE_FAILURE = 'ISSUE@DELETE_ISSUE_FAILURE';

export const deleteIssue = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_ISSUE_REQUEST,
  });
  try{
    const data = await http.delete(`/reports/${id}`);
    dispatch({
      type: DELETE_ISSUE_SUCCESS,
      payload: {
        issueId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_ISSUE_FAILURE,
    });
  };
};
