import _remove from 'lodash/remove';
import * as IssueActions from '../actions/issue';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  issuesList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  issueDetails: {
    isFetching: false,
    isFetched: false,
    issueData: null,
  },
};

const setIssueListFetching = fetching => state => ({
  ...state,
  issuesList: {
    ...state.issuesList,
    isFetching: fetching,
  },
});

const setIssueList = (state, { payload: { listData, total } }) => ({
  ...state,
  issuesList: {
    ...state.issuesList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setIssueFetching = fetching => state => ({
  ...state,
  issueDetails: {
    ...state.issueDetails,
    isFetching: fetching,
  },
});

const setIssue = (state, { payload: { issueData } }) => ({
  ...state,
  issueDetails: {
    ...state.issueDetails,
    issueData,
    isFetching: false,
    isFetched: true,
  },
});

const setIssueDeleted = (state, { payload: { issueId } }) => {
  const newListData = state.issuesList.listData;
  _remove(newListData, { id: issueId });

  const newState = {
    ...state,
    issuesList: {
      ...state.issuesList,
      listData: newListData,
    },
  };
  return newState;
};

const setTotalIssues = (state, { payload: { total, countType } }) => {
  if(!countType){

    return ({
      ...state,
      issuesList: {
        ...state.issuesList,
        total
      }
    });
  } else {

    return ({
      ...state,
      counts: {
        ...state.counts,
        [countType]: total
      }
    });
  }
};

const actionMap = {
  [IssueActions.LIST_ISSUE_REQUEST]: setIssueListFetching(true),
  [IssueActions.LIST_ISSUE_SUCCESS]: setIssueList,
  [IssueActions.LIST_ISSUE_FAILURE]: setIssueListFetching(false),
  [IssueActions.DELETE_ISSUE_SUCCESS]: setIssueDeleted,
  [IssueActions.FETCH_ISSUE_REQUEST]: setIssueFetching(true),
  [IssueActions.COUNT_ISSUE_SUCCESS]: setTotalIssues,
  [IssueActions.FETCH_ISSUE_SUCCESS]: setIssue,
  [IssueActions.FETCH_ISSUE_FAILURE]: setIssueFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


