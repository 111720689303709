import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../img/slogan.svg";
import { getProfile, logout } from "../actions/user";
import { getUserDetails } from "../selectors/user";

const Header = ({ getProfile, getUserDetails, userDetails, logout }) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const {
    userData,
    isFetching: userDataIsFetching,
    isFetched: userDataIsFetched,
  } = userDetails;

  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  let roleType = "";
  if (userDetails.isFetched && userDetails.userData) {
    roleType = userDetails.userData.role.type;
  }

  const signout = async () => {
    try {
      await logout();
      history.push("/login");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {roleType === "subinspector" ||
        roleType === "regional_inspector" ||
        roleType === "dip_admin"}
      {!(
        roleType === "subinspector" ||
        roleType === "regional_inspector" ||
        roleType === "dip_admin"
      ) && (
        <>
          <div className="container-fluid mx-auto printHide">
            <nav className="bg-white dark:bg-gray-800 shadow">
              <div className="max-w-7xl mx-auto px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    {!(
                      roleType === "subinspector" ||
                      roleType === "regional_inspector" ||
                      roleType === "dip_admin"
                    ) && (
                      <>
                        <Link className="flex-shrink-0" to="/dashboard">
                          <img className="w-64 h-12" src={logo} alt="Logo" />
                        </Link>
                        <div className="">
                          <div className="ml-10 flex items-baseline space-x-4">
                            <Link to="/dashboard">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Početna
                              </div>
                            </Link>
                            <Link to="/issues">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Prijave
                              </div>
                            </Link>
                            <Link to="/posts">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Novosti
                              </div>
                            </Link>
                            <Link to="/advice">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Savjeti
                              </div>
                            </Link>
                            {/* <Link to="/administrators">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Администратори
                              </div>
                            </Link> */}
                            <Link to="/users">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Korisnici
                              </div>
                            </Link>
                            <div>
                              <div onClick={toggleFiltersOpen}>
                                <div
                                  href="#"
                                  className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                >
                                  Statistika
                                </div>
                              </div>
                              <div
                                className={`absolute t-0 l-0 w-48 p-3 min-h-32 bg-white rounded-md shadow ${
                                  filtersOpen ? "block" : "hidden"
                                }`}
                              >
                                <div className="flex flex-col">
                                  <Link
                                    to="/statsByAge"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o godinama starosti
                                  </Link>
                                  <Link
                                    to="/statsByGender"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o polu
                                  </Link>

                                  <Link
                                    to="/statsByCity"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o mjestu boravka
                                  </Link>
                                  <Link
                                    to="/statsByEmployment"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o zaposlenima
                                  </Link>
                                  <Link
                                    to="/statsByMembership"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o članstvu u sindikatima
                                  </Link>
                                  <Link
                                    to="/statsByReports"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o prijavama za sebe/za nekog
                                    drugog
                                  </Link>
                                  <Link
                                    to="/statsByAnonimity"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o anonimnim prijavama
                                  </Link>
                                  {/* <Link
                                    to="/statsByReportType"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o tipu prijave
                                  </Link> */}
                                  <Link
                                    to="/statsByReportStatus"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o statusu prijave
                                  </Link>
                                  <Link
                                    to="/statsByCategory"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o povredi prava
                                  </Link>
                                  <Link
                                    to="/statsByIndustry"
                                    className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Statistika o djelatnosti
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <Link to="/profile">
                              <div className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium">
                                Profil
                              </div>
                            </Link>
                            <button
                              type="submit"
                              onClick={signout}
                              className="text-gray-800 hover:text-blue px-3 py-2 rounded-md text-sm font-medium"
                            >
                              Odjava
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  getProfile,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
