import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { fetchIssue, updateIssue } from "../../actions/issue";
import {
  createInternalComment,
  listInternalComments,
  fetchInternalComment,
  deleteInternalComment,
} from "../../actions/internalComment";
import { getIssueDetails } from "../../selectors/issue";
import { getInternalComments, getInternalCommentDetails } from "../../selectors/internalComment";
import BgImage from "../../components/BgImage";
import InternalCommentList from "../InternalReports/InternalCommentList";
import Header from "../../components/Header";
import Layout from "../../Layout";

const commentStartValues = {
  content: "",
  link: "",
};
const SSMInternalReport = ({
  fetchIssue,
  updateIssue,
  listInternalComments,
  createInternalComment,
  fetchInternalComment,
  deleteInternalComment,
  commentList,
}) => {
  let { id } = useParams();
  const [file, setFile] = useState(null);
  const [commentInitialValues, setCommentInitialValues] = useState({
    ...commentStartValues,
    internal_report: id,
  });

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  const fetch = useCallback(async () => {
    listInternalComments({
      "internal_report.id": id,
    });
  }, [id]);

  useEffect(() => {
    fetch();
  }, []);

  const comments = commentList.listData;


  const applyComment = async (values) => {
    let comment = null;
    try {
      comment = await createInternalComment(file, values);
      listInternalComments({ internal_report: id });
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="mt-20">
          <div className="text-2xl">
            Комуникација со Државен Инспекторат на труд
          </div>
          <div className="mt-5">
            <Formik
              initialValues={commentInitialValues}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={applyComment}
            >
              {(props) => (
                <Form>
                  <Field
                    name="content"
                    component="textarea"
                    rows="4"
                    placeholder="Внесете дополнителен коментар тука"
                    className="mt-5 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                  <label htmlFor="link" className="text-gray-700">
                    Линк
                  </label>
                  <Field
                    name="link"
                    type="text"
                    placeholder="Внесете линк тука"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                  <div className="mt-5">
                    <label htmlFor="files" className="text-gray-700">
                      Прикачи фотографија/документ
                    </label>
                    <input
                      type="file"
                      accept="*"
                      onChange={handleAttachment}
                      className="text-center text-base px-20 py-3 w-4/5 md:w-6/12"
                    />
                  </div>
                  <button
                    type="submit"
                    className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                  >
                    Испрати коментар
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mt-10 mb-40">
            <div className="text-2xl mb-3">Листа на коментари</div>
            <InternalCommentList
              comments={comments}
              deleteInternalComment={deleteInternalComment}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  issueDetails: getIssueDetails(state),
  commentList: getInternalComments(state),
  commentDetails: getInternalCommentDetails(state),
});

const mapDispatchToProps = {
  fetchIssue,
  updateIssue,
  createInternalComment,
  listInternalComments,
  deleteInternalComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(SSMInternalReport);
