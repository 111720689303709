import React from "react";
import Header from "../components/Header";
import BgImage from "../components/BgImage";
import Layout from "../Layout";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="mt-20">
          <div className="uppercase text-center text-5xl">Stranicu koju tražite ne postoji</div>
        </div>
      <div className="w-1/4 mt-16 mb-20 block mx-auto m-0 text-center">
        <Link to="/dashboard">
          <button
            type="button"
            className="py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
          >
            Nazad
          </button>
        </Link>
      </div>
    </Layout>
  );
};
export default NotFound;
