import React, { createRef, useState } from "react";
import { Link } from "react-router-dom";
import leftArrow from "../img/left-arrow.svg";
import rightArrow from "../img/right-arrow.svg";

const Paging = ({ total, ...props }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 5;
  const numberPages = Math.ceil(total / itemsPerPage);
  const pages = [];

  const handlePageChange = (pageNumber) => {
    props.setPageParams({ _limit: 10, _start: pageNumber * 10 });
    setCurrentPage(pageNumber);
  };

  for (
    let i = Math.max(currentPage - 2, 0);
    i <= Math.min(currentPage + 2, numberPages - 1);
    i++
  ) {
    pages.push(i);
  }

  return (
    <div className="flex flex-row">
      {currentPage !== 0 ? (
        <>
          <div>
            <a href="#">
              <img
                src={leftArrow}
                onClick={() => handlePageChange(currentPage - 1)}
                className="w-2 m-3"
                alt="Previous page"
              />
            </a>
          </div>
        </>
      ) : (
        ""
      )}
      {pages.map((item, idx) => {
        return (
          <a
            href="#"
            onClick={() => handlePageChange(item)}
            className="text-blue p-2"
          >
            {item + 1}
          </a>
        );
      })}
      {currentPage !== numberPages - 1 ? (
        <>
          <div>
            <a href="#">
              <img
                src={rightArrow}
                onClick={() => handlePageChange(currentPage + 1)}
                className="w-2 m-3"
                alt="Next page"
              />
            </a>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Paging;
