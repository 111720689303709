import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { Doughnut } from "react-chartjs-2";

const StatisticsByAnonimity = ({ countIssues }) => {
  const [anonymousCounts, setAnonymousCounts] = useState([0, 0]);
  const state = {
    labels: ["Podnesena anonimno", "Nije podnesena anonimno "],
    datasets: [
      {
        backgroundColor: ["#00408A", "#db3e3e"],
        hoverBackgroundColor: ["#002857", "#c22424"],
        data: anonymousCounts,
      },
    ],
  };

  useEffect(() => {
    statsByAnonimity();
  }, []);

  const statsByAnonimity = async () => {
    let anonymous = await countIssues({ anonymous: true });
    let unanonymous = await countIssues({ anonymous: false });
    setAnonymousCounts([anonymous, unanonymous]);
  };

  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Statistika o anonimnim prijavama
        </div>
        <Doughnut
          data={state}
          // width={300}
          // height={150}
          options={{
            // maintainAspectRatio: false,
            legend: {
              display: true,
              position: "top",
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
};

export default connect(null, mapDispatchToProps)(StatisticsByAnonimity);
